// Here you can add other styles
.custonbtn{
    float: right;
    width: 200px;
}
.userbtn{
   
    display: inline-block;
    float: right;
    /* text-shadow: 0 0 black; */
    /* height: 26px; */
    padding: 0px 34px;
    background-color: #48b6de;
    border-color: #48b6de;
}
.upActionbtn{
    display: inline-block;
    float: right;
    /* text-shadow: 0 0 black; */
    /* height: 26px; */
    padding: 0px 34px;
    background-color: #48b6de;
    border-color: #48b6de;
}
.userbtn:hover{
    background-color: #1b9fd0;
    border-color: #48b6de;
}
.error{
    margin-left: 8px;
    color: red;
    display: inline-block;
}
.hide{
    display: none;
}
.loginBtn{
    margin-left: -12px;
    margin-top: -14px;
}
.dpimg{


height: 100px;
width: 100px;
border-radius: 50%;
padding: 10px;
margin: 10px;
}
.profileHeading{
    position: absolute;
    top: 30%;
    font-size: 29px;
    left: 46px;
}
.qrMain{
    text-align: center;
}
.qrsec{
    h3{
        margin-bottom: 20px
    }
    padding: 20px;
    text-align: center;
}
.nameRow{
    width: 200px;
}
.idRow{
    // width: 100px;
}
.editRow{
    width: 150px;
}
.pagination{
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    float: right;
    margin-right: 17px;
}

.navbar-brand-full{
    
    display: none;
    
}
.navbar-nav{
    display: none;
}
.table-responsive{
    height: 500px;
}
#down{
    position: absolute;
    right: 19px;
    top: 12px;
    text-decoration: underline;
}
.rdrDefinedRangesWrapper{
    display: none !important;
}
canvas{
    height: 300px !important;
}

.locationsSelect{
    width: 100%;
    background-color: white;
    position: absolute;
    z-index: 10000;
    p{
        margin-bottom: 0px;
        padding: 5px;
        border-bottom: 1px solid #cccccc;
    }
  

}
.kpistatus{
    padding: 12px;
    color: blue !important;
    text-decoration: underline !important;;
    cursor: pointer;
}

.chartOPtions{
    position: absolute;
    top: 12px;
    right: 133px;
}
.breadcrumb{
    display: none;
}
.sidebar{
    background: #000B29;
    height: -webkit-fill-available !important;

}
.navbar-nav{
    display: none;
}
.app-header{
    background-color: #000B29;
    border: 0px;
    display: none;
}
.app-body{
    margin-top: 0px !important;
}
.app-footer{
    display: none;
}
body{
    background-color: white;
}
p{
    margin-bottom: 0px;
}
.card{
    box-shadow: #0000001a 0px 1px 3px 1px, #0000001a 0px 0px 0px 0px;
    border-radius: 4px;
    border:0px;
    padding: 20px 15px !important;
;
}
.sidebar .nav-link.active .nav-icon
{
    color: #000c2a !important;
}
.sidebar .nav-link:hover .nav-icon
{
    color: #000c2a !important;
}
.sidebar .nav-link.active
{
    background-color: white;
    color: #000c2a;

    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    text-decoration: underline;
}
.sidebar .nav-link:hover
{
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}
.sidebar .nav-item
{
    margin-left: 25px;
    padding: 6px 0px;
    font-size: 11px;
}
.brand-minimized{
    .sidebar .nav-item
    {
        margin-left: 8px;
        &:hover{
           
                overflow: hidden !important;
                background: transparent !important;
                    
        }
     
    }
    .sidebar{
        padding-top:80px;
        img{
            display: none;
        }
    }
  
}

.sidebar .nav-link .nav-icon
{
    color:white;
}
.sidebar .nav-link
{
    padding-left: 25px;
}
.sidebar .nav-link .nav-icon
{
    position: relative;
    left: -7px;
}
.createBtn{
    border-radius: 40px;
        background-color: rgb(67, 236, 155);
        padding: 10px 20px;
        font-size: 12px;
        border: 0;
        font-family: 'NoirPro-Bold';
}
.notibtn{
    border-radius: 60px;
    margin-right: 10px;
    height: 37px;
    width: 37px;
    text-align: center;
    border: 0px;
    i{
        position: relative;
        left: -1px;
        font-size: 16px;
    }
}
.games
{   cursor: pointer;
    box-shadow: #00000017 4px 4px 12px 1px, #0000001a 0px 0px 0px 0px;
    border-radius: 4px;
    border: 0px;
    padding: 20px;
    border: 0px;
    border-radius: 5px;
    p{
        // white-space: nowrap;
        // overflow: hidden;
        // display: block;
        // text-overflow: ellipsis;
        // line-height: 11px;
    }
    img{
        height: 40px;
        width: 40px;
    }
    .badges{
        height: 18px;
        width: 18px;
        margin-top: 2px;
    }
    .badges-small{
        height: 15px;
        width: 15px;
        margin-top: 2px;
        margin-right: 5px;
    }
    .text-muted {
        color: #555
    }
    .small {
        font-size: 12px;
    }
}

$small: 600px;
$medium: 900px;

.dashboard-search-bar {
    @media screen and (max-width: $small) {
        width: 60vw !important;
        position: absolute;
        top: 30px;
        left: -165px;
    }
    width: 100% !important;
    border-radius: 5px!important;
}
.dropdown-menu.show
{
    overflow: hidden;
overflow-y: auto;
    width: 375px;
    height: 400px;
    z-index: 1000000000;
}
.location-list-item
{
    border-bottom: 1px solid #dedede !important;
    margin: 10px !important;
    /* box-shadow: 6px 5px 5px -3px #dedede; */
    padding: 5px !important;
}
li.dropdown
{
    list-style: none;
}
.notification {
    p{

    }
   h6{
        margin-bottom: 0px;
    }
}

.img-thumbnail {
    padding: 0.25rem;
     background-color: white; 
   border: 0px; 
    border-radius: 2000px;
     max-width: 100px; 
     height: 100px;
    
}
.text-muted{
    color:#92949F
}
.datepicker{
    position: absolute;
    z-index: 5000;
}
.levelspecial{
    min-width: 190px  !important;
}

.calender-container-input {
    width: 97px;
    // max-width: 85px;
    background: #fff;
    border: 1px solid #fffcfc;
    // margin-right: 10px;
    padding-left: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px -1px #00000026;
    -webkit-box-shadow: 0px 0px 5px -1px #00000026;
}
.css-1pahdxg-control:hover
{
border-color: #dedede !important;
}
.css-1pahdxg-control
{
    box-shadow: 0 0 0 1px #dedede !important;
}
.create-activity-container .calender-container-input
{
    // min-width: 55px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.create-activity-container .input-dislay-pill .input-container
{
    min-width: 248px !important;
    min-height: 37px !important;
}

.create-activity-container .half .input-dislay-pill .input-container
{
    min-width: 63px !important;
    width: 65px !important;
    min-height: 37px !important;
}

   
.disabledfeild
{
    background-color: #F2F2F2;
    // width: 224px;
    padding: 7px 10px;
    border-radius: 8px;
    margin-bottom: 25px  !important;
    .price{
        text-align: right;
        font-size: 14px;
        font-weight: 900;
        position: relative;
        left: 60px;
    }
}
.create-activity-container .input-dislay-pill
{
    margin-bottom: 25px  !important;
    input{
        &:focus-visible{
            border-color: transparent;
        }
    }
}
.infor
{
    padding: 5px;
    background-color: white;
    border: 0.8px solid #dedede;
    padding-left: 10px;
    height: 106px;
    width: 100%;
    border-radius: 10px;
    
}
.customform
{
    p{
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
        font-family: 'NoirPro-SemiBold';
        margin-bottom: 5px;
    }
}
.switcher
{
    i{
        font-size: 16px;
        // position: relative;
        // top: 3px;
    }
 

    span{
        font-size: 12px;
        font-weight: 570;
    }
}

    input:focus,
select:focus,
textarea:focus,
button:focus {
    outline:  0.8px solid #dedede;
}


.infor1
{
    // padding-left: 15px;
    margin-bottom: 15px;
    input{
        padding: 5px;
        background-color: white;
        border: 0.8px solid #dedede;
        padding-left: 10px;

        border-radius: 10px;
        width: 100%;
    }
}
.productTextBox
{
    // padding-left: 15px;
    margin-bottom: 15px;
    input{
        background-color: white;
        border: 0.8px solid #dedede;
        // padding-left: 10px;
        padding: 10px;

        border-radius: 10px;
        width: 100%;
    }
}
.scrollLine {
    background: #939393;
    height: 200px;
    width: 1px;
}
.circleScroll {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #939393;
    position: absolute;
    right: -6px;
}

.scorecard-modal {
    border: 1px solid;
    margin: 20px;
    background-color: #000c28;
    
    .scorecard-header {
        text-align: center;
        color: white;
        font-size: 3em;
        font-weight: bold;
    }
    .scorecard-body {
        .players-avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 1.5rem; 
        }
        .score-vs {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
            color: white;
            font-size: 1.5rem;
        }
        .score-number {
            font-size: 18em;
            color: white;
            font-weight: bold;
            display: flex;
            justify-content: center;
            font-family: NoirPro-SemiBold;
        }
        .inc-dec-container {
            display: flex;
            justify-content: center;
            position: relative;
            bottom: 30px; 
        }
    }
}

.dropDownLang {
    .dropdown-menu.show {
        height: auto !important;
        width: auto;
    }
    a {
        color: #000000;
    }
}

.currencyDrop {
    .dropdown-menu.show {
        max-height: 300px !important;
        overflow-y: scroll !important;
        position: absolute;
    left: -61px;
    top: 42px;
        // width: auto !important;
        
    }
    a {
        color: #000000;
    }
}
.success-button {
    button {
         /* width: 30vw; */
    background-color: #43ec9b;
    padding: 10px;
    font-weight: bold;
    color: #000c28;
    width: 227px;
    margin-top: 30px;
    text-transform: uppercase;
    font-size: 17;
    }
  }
  .customOutline{
      border-color: #000B29;
      color: #000B29;
      background-color: white;
      &:hover{
        background-color: #000B29;
        color: #F2F2F2;
      }
  }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus{
    box-shadow:none  !important;
  }
  .btn-secondary:focus, .btn-secondary.focus{
    box-shadow:none  !important;
  }
  .modal-content{

    border-color: 0px;
  }
  .premiumtn{

    background-color: #43EC9B;
    border: 0;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 21px;
    margin-bottom: 25px;

  }

  .modal-backdrop.show {
    opacity: 0.6 !important;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000d29 !important;
}
.modal-content{
    border-radius: 15px !important;
}
.modal-header{
border-bottom: 0px  !important;
}
.modal-footer{
    border-top: 0px  !important;
    }

    .save-Btn {
        background: #42ed9b;
        color: #000;
        border: none;
        font-weight: bold;
        font-size: 12px;
        margin-top: 50px;
        text-transform: uppercase;
      }
      .form-control:disabled, .form-control[readonly]
      {
          background-color: #f4f4f4;
      }


      .line{
        border-right: 1px solid #d3d3d3;
      }
      .rdt_TableHeadRow{
          text-transform: uppercase;
          font-weight: bolder;
      }
      .imageEdit{
        position: absolute;
        bottom: 32px;
        right: 42px;
        font-size: 12px;
        background: #000a29;
        border-radius: 10px;
        /* padding: 4px; */
        color: #42ed9b;
        width: 20px;
        height: 20px;
        text-align: center;
        padding-top: 5px;
        
      }
      .oneLine{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        // width:130px; 
      }
      .jaja{
        label{
            position: relative;
    top: 13px;
        }
      }

      .jaja2{
        label{
            position: relative;
    top: 13px;
        }
        .oneLine{
            width:80px; 
        }
      }
      .simplebtn{
        background-color: white !important;
        border: 0;
        color: #000a29 !important;
        font-size: 16px;
      }
      .heJwJj 
      {
          background-color:#000618 !important;
          height: 342px !important;
          font-family: NoirPro;
      }
      .gKyapV
      {
          background-color: #43EC9B !important;
          font-family: NoirPro;
      }
      .cEICfj ,.MTWGZ
      {
         color: #000618 !important;
         font-family: NoirPro;
      }
      .dsKVpZ
      {
          color: #000618 !important;
          font-family: NoirPro-Medium;
      }
      .teamaa
      {
        .oneLine{
            width:160px; 
        }
      }

      .rightclm{
        border-right: 1px solid #BDBDBD;
        margin-right: 17px;
        padding-right: 17px;

      }
      .eCkmfb{
        background-color: #F6F6F6  !important;
    
        
      }
      .eCkmfb:not(:last-of-type){
          border: 0 !important;
      }
      .fhCFWO:not(:last-of-type)
      {
          border: 0 !important;
      }


      .react-datepicker__header{
          border-bottom: 0  !important;
      }
      .react-datepicker{
        border: 1px solid #cccccc  !important;
      }

      .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected
      {
        background-color: #000821 !important;
      }
      .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range
      {
        background-color: #000821 !important;
      }

      .notidiv{
        padding: 10px 20px;
        border-bottom: 1px solid #cccccc;
        cursor: pointer;
      }
      .react-datepicker__header
      {
          background-color: white !important;
      }
      .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected
      {
        background-color: #000821 !important;
      }
      input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.red{
   
    height: 10px;
    width: 10px;
    background-color: red;
    position: absolute;
    border-radius: 14px;
    left: inherit;
    right: 29px;
    top: 7px;
    z-index: 100;

}
.onelinegraph{
    width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 15px;
    position: relative;
    top: 9px;

}
.rewardsitem{
    lebel{
        font-size: 10px;
    }
}
.lib{
    padding: 2px !important;
    margin-bottom: 10px !important;
}
.searchBar{
    background-color: #000a29;
    color: #2f3438;
    border: 0.5px solid #ffffff80;
    width: 64% !important;
    position: relative;
    top: 9px;
    float: right;
    right: 22px;
}
.searchBar1{
    background-color: #000a29;
    color: #2f3438;
    border: 0.5px solid #ffffff80;
    width: 45% !important;
    position: relative;



}